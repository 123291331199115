import React, { useState, useEffect } from "react";
import SocialIcon from "@/src/components/molecules/socialIconList";
import SiteTitle from "../molecules/siteTitle";
import StarRating from "../molecules/rating";
import RatingDialog from "./ratingDialog";
import { WebVisitor } from "../atoms/webVisitor";
import { getOrCreateIdentifier } from "../../helpers/identifierHelper";
import { FaSleigh } from "react-icons/fa";
import RatingRepository from "@/src/repositories/ratingRepository";
import { RatingModel } from "@/src/models/ratingModel";
import StarLoading from "../atoms/starLoading";
import { useRatingRefresh } from "@/src/hooks/useRatingRefresh";
import RelatedLinkList from "../molecules/relatedLinkList";

export default function Footer() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [identifier, setIdentifier] = useState<string | null>(null);
  const [isRated, setIsRated] = useState<boolean>(true);
  const [rating, setRating] = useState<RatingModel>();
  const [loading, setLoading] = useState<boolean>(true);

  const ratingRepository = new RatingRepository();
  const ratingKey = useRatingRefresh();

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    const id = getOrCreateIdentifier();
    setIdentifier(id);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (!identifier) return;

      try {
        const isSubmitted = await ratingRepository.check(identifier as string);
        setIsRated(isSubmitted);

        // After checking, fetch the rating API
        const response = await ratingRepository.fetchRatings();
        if (response.data) {
          setRating(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [identifier, ratingKey]);

  return (
    <footer className="mt-2 border-t border-[#D4D4D4] bg-[#F9F9FC] pt-8 text-black">
      {isDialogOpen && <RatingDialog onClose={closeDialog} />}
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          <div className="inline-flex flex-col items-start justify-start gap-5 max-md:gap-2">
            {/* <h5 className="text-lg font-bold">JDIH DPRD Provinsi Bali</h5> */}
            <div className="max-md:font-600 text-neutral-900 max-md:text-2xl">
              <SiteTitle variant="footer" />
            </div>
            <h6 className="font-semi-bold text-md mb-4 text-[#171717]">
              Jaringan Dokumentasi dan Informasi Hukum DPRD Provinsi Bali
            </h6>
            <div className="hidden">
              <h5 className="text-md font-semi-bold mb-1">Kontak Kami</h5>
              <p className="text-gray-400">
                Jl. Dr. Kusuma Atmaja No. 3, Niti Mandala, Panjer, Denpasar
                Selatan, Kota Denpasar, Bali 80234
              </p>
              <p className="text-gray-400">Phone: (0361) 23462</p>
              <p className="text-gray-400">email: setwan@baliprov.go.id</p>
            </div>
            <div className="">
              {/* <h5 className="text-md font-semi-bold mb-1">Follow Us</h5> */}
              <SocialIcon />
            </div>
          </div>
          <div>
            <h5 className="mb-4 font-semibold text-[#191970]">Tautan</h5>
            <RelatedLinkList />
          </div>
          <div>
            <h5 className="mb-3 font-semibold text-[#191970]">Rating Situs</h5>
            {loading ? (
              <div className="flex">
                <StarLoading />
              </div>
            ) : (
              <StarRating
                key={ratingKey}
                total={rating?.total ?? 0}
                count={rating?.count ?? 0}
                rating={rating?.rating ?? 0}
                onClick={openDialog}
                canRating={isRated}
              />
            )}
            <div>
              <h5 className="mb-3 mt-3 font-semibold text-[#191970]">
                Statistik Kunjungan
              </h5>
              <WebVisitor />
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto mt-4 border-t p-4">
        <p className="pb-1 pt-3 text-center text-sm text-[#737373]">
          &copy; Copyright 2024, All Rights Reserved by DISKOMINFOS Provinsi
          Bali
        </p>
      </div>
    </footer>
  );
}
