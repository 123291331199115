import RelatedLink from "../atoms/relatedLink";

export default function RelatedLinkList() {
  return (
    <ul className="space-y-2">
      <li>
        <RelatedLink url="https://jdihn.go.id" text="JDIHN" />
      </li>
      <li>
        <RelatedLink
          url="https://jdih.baliprov.go.id"
          text="JDIH Provinsi Bali"
        />
      </li>
    </ul>
  );
}
